<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid">
            <div class="md:col-8 sm:col-12 comp-grid">
              <div class="card">
                <div class="text-xl text-primary font-bold">Add Campus</div>
              </div>
              <!-- <Breadcrumb :home="{
                icon: 'pi pi-arrow-left text-lg mr-3',
                label: 'Back',
                command: () => {
                  $router.go(-1);
                },
              }" :model="[
                {
                  label: 'Campuses',
                  to: '/institution_campuses',
                },
                {
                  label: 'Add New',
                  to: '/institution_campuses/add',
                },
              ]" /> -->
            </div>
          </div>
        </div>
      </section>
    </template>

    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="sm:col-12 md:col-8 comp-grid">
            <div class="card nice-shadow-18">
              <div>
                <form ref="observer" tag="form" @submit.prevent="startSaveRecord()" @reset="resetForm">
                  <div class="grid">
                    <div class="col-12 md:col-12">
                      <div class="mb-2 font-bold text-sm">Institution *</div>
                      <div class="">
                        <api-data-source @loaded="(response) =>
                            mapOptionField(response, 'caps_institution_id')
                          " api-path="components_data/caps_institution_id_option_list">
                          <template v-slot="req">
                            <Dropdown class="w-full" :class="getErrorClass('caps_institution_id')"
                              :loading="req.loading" optionLabel="label" optionValue="value"
                              ref="ctrlcaps_institution_id" v-model="formData.caps_institution_id"
                              :options="req.response" label="Institution" placeholder="Select ..." :filter="true">
                            </Dropdown>
                          </template>
                        </api-data-source>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Campus Name *</div>
                      <div class="">
                        <InputText ref="ctrlcampus_name" v-model.trim="formData.campus_name" label="Campus Name"
                          type="text" placeholder="Enter Campus Name" class="w-full"
                          :class="getErrorClass('campus_name')">
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Campus Code (optional)</div>
                      <div class="">
                        <InputText ref="ctrlcampus_code" v-model.trim="formData.campus_code" label="Campus Code"
                          type="text" placeholder="Enter Campus Code" class="w-full"
                          :class="getErrorClass('campus_code')">
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="mb-2 font-bold text-sm">
                        Campus Description
                      </div>
                      <div class="">
                        <Textarea :class="getErrorClass('campus_description')" class="w-full"
                          ref="ctrlcampus_description" rows="2" v-model="formData.campus_description"
                          placeholder=" Campus Description" type="textarea">
                    </Textarea>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        Start Of Accreditation
                      </div>
                      <div id="ctrl-start_of_accreditation-holder">
                        <Calendar :showButtonBar="true" class="w-full" :class="getErrorClass('start_of_accreditation')"
                          dateFormat="yy-mm-dd" v-model="formData.start_of_accreditation" :showIcon="true"
                          mask="DD/MM/YYYY" outlined dense @dateSelect="validateAccreditationYear" />
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        End Of Accreditation
                      </div>
                      <div id="ctrl-end_of_accreditation-holder">
                        <Calendar :showButtonBar="true" class="w-full" :class="getErrorClass('end_of_accreditation')"
                          dateFormat="yy-mm-dd" v-model="formData.end_of_accreditation" :showIcon="true"
                          mask="DD/MM/YYYY" outlined dense @dateSelect="validateAccreditationYear" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-4 font-bold text-sm">
                        Is this an accredited campus? *
                      </div>
                      <div id="ctrl-is_accredited-holder">
                        <div class="field-radiobutton" v-for="option of $menus.yes_or_noItems" :key="option.value">
                          <RadioButton :class="getErrorClass('is_accredited')" :id="option.value"
                            name="ctrlis_accredited" :value="option.value" v-model="formData.is_accredited" />
                          <label :for="option.value">{{ option.label }} </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button class="p-button-primary" type="submit" label="Submit" icon="pi pi-send" :loading="saving" />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
          <!-- <div class="sm:col-12 md:col-4">
            <div class="sticky-item"><file-svg-page></file-svg-page></div>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { AddPageMixin } from "../../mixins/addpage.js";
import { PageMixin } from "../../mixins/page.js";
// import fileSvgPage from "../custom/file_svg.vue";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addInstitutioncampusesPage",
  // components: { fileSvgPage },
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "institution_campuses",
    },
    routeName: {
      type: String,
      default: "institution_campusesadd",
    },
    apiPath: {
      type: String,
      default: "institution_campuses/add",
    },
  },
  data() {
    return {
      formData: {
        caps_institution_id: this.$route.params.id ? this.$route.params.id : "",
        campus_name: "",
        campus_description: "",
        campus_code: "",
        start_of_accreditation: null,
        end_of_accreditation: null,
        is_accredited: "Yes",
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add New Campus";
      },
    },
  },
  validations() {
    let formData = {
      caps_institution_id: { required },
      campus_name: { required },
      campus_description: {},
      campus_code: {},
      start_of_accreditation: {},
      end_of_accreditation: {},
      is_accredited: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("institution_campuses", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["institution_campus_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          // if (this.redirect) this.navigateTo(`/institution_campuses`);
          this.$router.push(this.$route.path);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    resetForm() {
      this.formData = { caps_institution_id: "", campus_name: "", campus_description: "", campus_code: "", start_of_accreditation: null, end_of_accreditation: null, is_accredited: "Yes", };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() { },
  async created() { },
};
</script>
<style scoped></style>
